.btn-order-analysis {
  display: inline-block;
  margin: 0;
  padding: 10px 20px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  text-align: center;
  color: var(--white);
  background-color: var(--sky-blue);
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms linear;
  align-self: flex-start;

  @media screen and (min-width: 768px) {
    padding: 15px 25px;
  }
}
