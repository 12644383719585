.hero {
  position: relative;
  background-color: rgba(34, 60, 80, 0.6);

  @include for-size(desktop) {
    padding: 50px 0;
    background-color: inherit;
    background-image: url(../images/hero/bg/drop-large.png);
    background-repeat: no-repeat;
    background-position: left bottom, right 90%;
    background-size: 30%, 25%;

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/hero/bg/drop-large@2x.png);
    }
  }
}
.hero--shadow-bottom {
  box-shadow: 0px 0px 10px -2px rgba(34, 60, 80, 0.3);

  @include for-size(desktop) {
    box-shadow: none;
  }
}
.container-hero {
  display: none;

  @include for-size(desktop) {
    display: flex;
    justify-content: space-between;
    width: 1170px;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
  }
}
.container-cards {
  width: 70%;
}
.list-cards {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.list-cards__questions--card {
  margin: 0;
  font-weight: 700;
  font-size: 23px;
  color: var(--white);
}
.container-order-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
}
.btn-order-analysis__desktop {
  padding: 20px 40px;
  max-width: 350px;
  box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.2);
  transition: all 250ms linear;
  border: 1px solid var(--sky-blue);
  align-self: center;

  &:not(:last-child) {
    margin-bottom: 35px;
  }

  &:hover,
  &:focus {
    color: var(--sky-blue);
    background-color: var(--white);
  }
}
