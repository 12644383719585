.section-policy {
  padding: 50px 0;
  color: var(--accent-cl);
  background-color: var(--main-cl);

  p {
    text-align: justify;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}

.list-info {
  margin: 15px;
  padding-left: 40px;
  list-style-type: disc;

  .item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.title {
  margin-bottom: 40px;
  text-align: center;
}

.back-link {
  display: block;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
  color: var(--accent-cl);
}
