.montage-swiper {
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .swiper-pagination {
    left: 0;
    bottom: 5px;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--sky-blue);
  }

  .swiper-pagination-bullet {
    background-color: var(--pagination-cl);
  }
}

@include for-size(tablet) {
  .montage-swiper {
    padding: 0 50px 40px 50px;

    .swiper-button-next,
    .swiper-button-prev {
      display: block;
      width: 30px;
      height: 30px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      outline: none;
      border-radius: 50%;

      .icon {
        width: 30px;
        height: 30px;
        fill: var(--logo-cl);
      }

      &::after {
        content: "";
      }
    }
  }
}

@include for-size(desktop) {
  .montage-swiper {
    .swiper-button-next,
    .swiper-button-prev {
      width: 40px;
      height: 40px;
      box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2);

      .icon {
        width: 40px;
        height: 40px;
      }
    }

    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: 0;
    }
  }
}
