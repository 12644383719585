.section-result {
  background-color: rgb(239 252 253);

  .title-result {
    margin-bottom: 40px;
    justify-content: center;
  }

  .text {
    margin: 0 0 30px 0;

    @include for-size(desktop) {
      width: 79%;
    }
  }

  @include for-size(desktop) {
    background-image: url(../images/result/bg/circle-blue.png),
      url(../images/result/bg/circle-white.png),
      url(../images/result/bg/circle-blue.png),
      url(../images/result/bg/circle-white.png),
      url(../images/result/bg/circle-blue.png),
      url(../images/result/bg/circle-white.png),
      url(../images/result/bg/circle-blue.png),
      url(../images/result/bg/circle-white.png),
      url(../images/result/bg/circle-blue.png),
      url(../images/result/bg/circle-white.png),
      url(../images/result/bg/circle-blue.png),
      url(../images/result/bg/circle-white.png),
      url(../images/result/bg/circle-blue.png),
      url(../images/result/bg/circle-white.png),
      url(../images/result/bg/circle-blue.png),
      url(../images/result/bg/circle-white.png);
    background-repeat: no-repeat;
    background-size: 110px, 90px, 85px, 80px, 60px, auto, 40px, 70px, 40px, 50px,
      55px, 80px, 60px, 100px, 110px, auto;
    background-position: 5% 95%, 35% 29%, 18% 75%, 10% 65%, 15% 50%, 18% 10%,
      -1% 8%, 5% 40%, 85% 20%, 95% 10%, 75% 35%, 90% 50%, 95% 38%, 101% 70%,
      90% 85%, 10% 25%;

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/result/bg/circle-blue@2x.png),
        url(../images/result/bg/circle-white@2x.png),
        url(../images/result/bg/circle-blue@2x.png),
        url(../images/result/bg/circle-white@2x.png),
        url(../images/result/bg/circle-blue@2x.png),
        url(../images/result/bg/circle-white@2x.png),
        url(../images/result/bg/circle-blue@2x.png),
        url(../images/result/bg/circle-white@2x.png),
        url(../images/result/bg/circle-blue@2x.png),
        url(../images/result/bg/circle-white@2x.png),
        url(../images/result/bg/circle-blue@2x.png),
        url(../images/result/bg/circle-white@2x.png),
        url(../images/result/bg/circle-blue@2x.png),
        url(../images/result/bg/circle-white@2x.png),
        url(../images/result/bg/circle-blue@2x.png),
        url(../images/result/bg/circle-white@2x.png);
    }
  }
}
.list-sale {
  @include for-size(tablet) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }
  @include for-size(desktop) {
    gap: 10px;
  }
}
.list-sale__item {
  padding: 20px;
  text-align: center;
  border: 2px solid var(--sky-blue);
  border-radius: 14px;
  background-color: var(--white);
  box-shadow: 1px 2px 8px -3px rgba(34, 60, 80, 0.2);
  cursor: pointer;

  @include for-size(mobile-only) {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  @include for-size(tablet) {
    margin: 0;
    flex-basis: calc((100% - 5px * 4) / 2);
  }
  @include for-size(desktop) {
    flex-basis: calc((100% - 10px * 4) / 4);
    transition: border-color 250ms linear;

    &:hover,
    &:focus {
      border-color: var(--icon-cl);
    }
    &:hover .list-sale__icon,
    &:focus .list-sale__icon {
      fill: var(--icon-cl);
    }
  }
}
.list-sale__icon {
  width: 70px;
  height: 70px;
  fill: var(--sky-blue);
  transition: fill 250ms linear;
}
.list-sale__title {
  margin: 20px 0 0 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.62;
  color: var(--text-font-second);

  @include for-size(desktop) {
    font-size: 18px;
    line-height: 1.67;
  }
}
.result-container-table {
  margin-top: 50px;
  margin-bottom: 50px;

  @include for-size(desktop) {
    margin-bottom: 70px;
  }
}

.questions-title {
  margin: 0 0 40px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.18;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: capitalize;
  color: var(--title-cl);

  @include for-size(desktop) {
    margin: 0 0 50px 0;
    font-size: 32px;
    line-height: 1.16;
  }
}

.list-questions {
  margin-bottom: 60px;

  .item {
    position: relative;
    padding: 20px;
    background-color: #b1f7ff;
    border: none;
    border-radius: 18px;
    transition: all 250ms ease;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.active {
      background-color: var(--white);
    }
  }

  .item .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .question {
    margin: 0;
    font-weight: 700;
  }

  .open-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    .icon {
      width: 24px;
      height: 24px;
      fill: var(--logo-cl);
    }
  }
}

@include for-size(tablet) {
  .list-questions {
    display: flex;
    flex-direction: column;
    align-items: center;

    .item {
      width: 650px;
    }
  }
}

@include for-size(desktop) {
  .questions-title {
    margin: 0 0 75px 0;
  }

  .desktop-box {
    position: relative;
    height: 405px;
  }

  .list-questions {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);

    .item {
      width: 750px;
      padding: 30px;

      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }
  }
}
