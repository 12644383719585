@import "./utils/variables";
@import "./utils/mixins";
@import "./base/common";
@import "./components/sales";
@import "./components/header";
@import "./components/swiper-hero";
@import "./components/btn";
@import "./components/hero-cards";
@import "./components/up-btn";
@import "./components/table";
@import "./components/swiper-montage";
@import "./components/swiper-work";
@import "./components/swiper-clients";
@import "./components/list-social";
@import "./components/form";
@import "./components/footer";
@import "./components/pnotify";
@import "./components/container-for-btn";
@import "./layouts/hero";
@import "./layouts/about";
@import "./layouts/result";
@import "./layouts/maintenance";
@import "./layouts/montage";
@import "./layouts/favourite";
@import "./layouts/work";
@import "./layouts/clients";
@import "./layouts/order-form";
@import "./layouts/contacts";
@import "./layouts/policy";
