.hero-swiper {
  @media screen and (min-width: 1200px) {
    display: none;
  }
  overflow: hidden;
}
.swiper-slide__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  min-height: 100vh;
  background-color: green;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media screen and (min-width: 768px) {
    min-height: 640px;
  }
}

.swiper-slide__box--img-girl {
  background-image: linear-gradient(
      180deg,
      rgba(2, 1, 17, 0.6) 0%,
      rgba(2, 1, 17, 0.6) 50%,
      rgba(2, 1, 17, 0.6) 100%
    ),
    url(../images/hero/v2/bg-img-1.jpg);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: linear-gradient(
        180deg,
        rgba(2, 1, 17, 0.6) 0%,
        rgba(2, 1, 17, 0.6) 50%,
        rgba(2, 1, 17, 0.6) 100%
      ),
      url(../images/hero/v2/bg-img-1@2x.jpg);
  }
}
.swiper-slide__box--img-child {
  background-image: linear-gradient(
      180deg,
      rgba(2, 1, 17, 0.6) 0%,
      rgba(2, 1, 17, 0.6) 50%,
      rgba(2, 1, 17, 0.6) 100%
    ),
    url(../images/hero/v2/bg-img-2.jpg);
  background-position: center;

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: linear-gradient(
        180deg,
        rgba(2, 1, 17, 0.6) 0%,
        rgba(2, 1, 17, 0.6) 50%,
        rgba(2, 1, 17, 0.6) 100%
      ),
      url(../images/hero/v2/bg-img-2@2x.jpg);
  }
}
.swiper-slide__box--img-green {
  background-image: linear-gradient(
      180deg,
      rgba(2, 1, 17, 0.6) 0%,
      rgba(2, 1, 17, 0.6) 50%,
      rgba(2, 1, 17, 0.6) 100%
    ),
    url(../images/hero/v2/bg-img-3.jpg);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: linear-gradient(
        180deg,
        rgba(2, 1, 17, 0.6) 0%,
        rgba(2, 1, 17, 0.6) 50%,
        rgba(2, 1, 17, 0.6) 100%
      ),
      url(../images/hero/v2/bg-img-3@2x.jpg);
  }
}
.swiper-slide__box--hands {
  background-image: linear-gradient(
      180deg,
      rgba(2, 1, 17, 0.6) 0%,
      rgba(2, 1, 17, 0.6) 50%,
      rgba(2, 1, 17, 0.6) 100%
    ),
    url(../images/hero/v2/bg-img-4.jpg);

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: linear-gradient(
        180deg,
        rgba(2, 1, 17, 0.6) 0%,
        rgba(2, 1, 17, 0.6) 50%,
        rgba(2, 1, 17, 0.6) 100%
      ),
      url(../images/hero/v2/bg-img-4@2x.jpg);
  }
}
.swiper-slide__questions {
  margin: 0 0 30px 0;
  font-weight: 700;
  font-size: 28px;
  color: var(--white);

  @media screen and (min-width: 768px) {
    margin: 0 0 50px 0;
    font-size: 36px;
  }
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: var(--sky-blue);
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: var(--white);
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 50px;
  left: -80px;

  @media screen and (min-width: 380px) {
    left: -110px;
  }
  @media screen and (min-width: 440px) {
    left: -140px;
  }
  @media screen and (min-width: 480px) {
    left: 0px;
    bottom: 20px;
  }
}
