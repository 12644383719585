.section-favourite {
  text-align: center;
  background-color: #effcfe;

  .section-title {
    justify-content: center;
  }

  .container-for-btn {
    margin-top: 70px;

    @include for-size(desktop) {
      margin-top: 100px;
    }
  }

  @include for-size(desktop) {
    text-align: left;

    .section-title {
      justify-content: flex-start;
    }
  }
}
.section-favourite__subhead {
  margin: 0 0 20px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.67;
  color: var(--text-font-second);

  @include for-size(desktop) {
    margin: 0 0 30px 0;
    font-size: 28px;
    line-height: 1.18;
  }
}
.favourite-list {
  @include for-size(tablet) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -5px;
  }
  @include for-size(desktop) {
    margin: -10px;
  }
}
.favourite-list__item {
  @include for-size(mobile-only) {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  @include for-size(tablet) {
    margin: 5px;
    flex-basis: calc((100% - 4 * 10px) / 2);
  }
  @include for-size(desktop) {
    margin: 10px;
    flex-basis: calc((100% - 4 * 20px) / 4);
    text-align: center;
    cursor: pointer;

    &:hover .favourite-list__icon,
    &:focus .favourite-list__icon {
      fill: var(--icon-cl);
    }
  }
}
.favourite-list__icon {
  width: 100px;
  height: 100px;
  fill: var(--sky-blue);
  transition: fill 250ms linear;
}
.favourite-list__num {
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  color: var(--title-cl);

  @include for-size(desktop) {
    margin-bottom: 10px;
  }
}
.favourite-list__text {
  margin: 0;
}
