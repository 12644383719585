.section-maintenance {
  background-repeat: no-repeat;
  background-size: cover, 440px;
  background-position: center, right 500px;
  background-image: linear-gradient(
    180deg,
    rgba(239, 252, 253, 1) 0%,
    rgba(255, 255, 255, 0.3982843137254902) 30%,
    rgba(255, 255, 255, 0.4010854341736695) 100%
  );

  .text {
    text-align: justify;

    &.overhead {
      font-weight: 700;
    }
  }
}

.box-text {
  margin-bottom: 50px;

  .text-list {
    margin-bottom: 30px;

    .item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .text {
        margin: 0;

        .bold {
          font-weight: 700;
        }
      }
    }
  }
}

.maintenance-img-list {
  margin-bottom: 50px;

  .item {
    position: relative;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .maintenance-card {
    transition: all 400ms ease;

    .card-img {
      transform: translateX(0);
      transition: all 400ms ease;
    }

    &:hover .container-info,
    &:focus .container-info {
      transform: translateX(0);
    }

    &:hover img,
    &:focus img {
      transform: translateX(10%);
    }
  }

  .container-info {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 10px;
    width: 40%;
    height: 100%;
    background-color: var(--title-cl);
    transform: translateX(-105%);
    transition: all 400ms ease;
    z-index: 2;

    .info {
      font-size: 13px;
      font-weight: 600;
      line-height: 1.1;
      color: var(--white);
    }
  }
}

@include for-size(tablet) {
  .section-maintenance {
    .box-text {
      width: 75%;
    }

    .title-maintenance {
      width: 70%;
    }
  }

  .maintenance-img-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .item {
      flex-basis: calc((100% - 1 * 20px) / 2);
      &:not(:last-child) {
        margin: 0;
      }
    }
  }
}

@include for-size(desktop) {
  .section-maintenance {
    background-image: linear-gradient(
        180deg,
        rgba(239, 252, 253, 1) 0%,
        rgba(255, 255, 255, 0.3982843137254902) 30%,
        rgba(255, 255, 255, 0.4010854341736695) 100%
      ),
      url(../images/maintenance/v2/bg-img.jpg);

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: linear-gradient(
          180deg,
          rgba(239, 252, 253, 1) 0%,
          rgba(255, 255, 255, 0.3982843137254902) 30%,
          rgba(255, 255, 255, 0.4010854341736695) 100%
        ),
        url(../images/maintenance/v2/bg-img@2x.jpg);
    }
  }

  .maintenance-img-list {
    gap: 40px;
    margin-bottom: 70px;

    .item {
      flex-basis: calc((100% - 1 * 40px) / 2);
    }

    .container-info {
      .info {
        font-size: 14px;
      }
    }
  }
}
