.section-contacts {
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.85) 0%,
      rgba(255, 255, 255, 0.85) 50%,
      rgba(255, 255, 255, 0.85) 100%
    ),
    url(../images/contacts/bg/contacts-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 40% 0;

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.85) 0%,
        rgba(255, 255, 255, 0.85) 50%,
        rgba(255, 255, 255, 0.85) 100%
      ),
      url(../images/contacts/bg/contacts-bg@2x.jpg);
  }

  @include for-size(desktop) {
    padding-left: 100px;
    padding-right: 100px;
    width: 50%;
  }
}
.section-container-contacts {
  @include for-size(desktop) {
    width: 100%;
  }
}
address {
  margin-bottom: 30px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}
.address-list__item {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
.address-list__link {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.66;
  color: var(--text-font-second);

  @include for-size(tablet) {
    font-size: 24px;
  }
}
.address-list__icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  fill: gray;
}
.section-contacts__time-work-box {
  margin-bottom: 40px;
}
.section-contacts__work-title {
  margin: 0 0 10px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.67;
  color: var(--text-font-second);

  &::before {
    content: "";
    display: inline-block;
    margin-right: 5px;
    height: 2px;
    width: 40px;
    background-color: currentColor;
  }

  @include for-size(desktop) {
    font-size: 28px;
    line-height: 1.18;
  }
}
.section-contacts__time-work {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.66;
}
.section-contacts__work-title--social {
  margin-bottom: 30px;
}
