html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.86;
  letter-spacing: 0.02em;
  color: var(--text-font);

  @include for-size(desktop) {
    font-size: 18px;
  }
}

.body-hidden-scroll {
  overflow: hidden;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a,
button,
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container {
  width: 100%;
  padding: 0 15px;

  @include for-size(mobile) {
    width: 480px;
    margin: 0 auto;
  }
  @include for-size(tablet) {
    width: 768px;
  }
  @include for-size(desktop) {
    width: 1200px;
  }
}

.link {
  text-decoration: none;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section {
  padding: 60px 0;

  @include for-size(tablet) {
    padding: 80px 0;
  }
  @include for-size(desktop) {
    padding: 100px 0;
  }
}

.section-title {
  display: flex;
  align-items: baseline;
  margin: 0 0 40px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.18;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: var(--title-cl);

  @include for-size(desktop) {
    margin: 0 0 50px 0;
    font-size: 42px;
    line-height: 1.16;
  }
}

.is-hidden {
  display: none;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  transition: all 250ms ease;
}
