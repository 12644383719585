@mixin for-size($range) {
    $mobile: 480px;
    $tablet: 768px;
    $desktop: 1200px;

    @if $range == mobile-only {
        @media screen and (max-width: #{$tablet - 1}) {
            @content;
        }
    } @else if $range == mobile {
        @media screen and (min-width: $mobile) {
            @content;
        }
    } @else if $range == tablet {
        @media screen and (min-width: $tablet) {
            @content;
        }
    } @else if $range == desktop {
        @media screen and (min-width: $desktop) {
            @content;
        }
    }
}