.section-work {
  background-color: var(--sky-blue-bg);

  @include for-size(tablet) {
    text-align: start;
  }
}
.work-container-content {
  @include for-size(tablet) {
    display: flex;
  }
  @include for-size(desktop) {
    flex-direction: row-reverse;
  }
}
.work-container-content__text-box {
  margin: 0 0 30px 0;

  @include for-size(tablet) {
    margin: 0 20px 0 0;
    width: 50%;
  }
  @include for-size(desktop) {
    margin: 0 0 0 50px;
  }
}
.work-container-content__text {
  margin: 0;
  text-align: justify;

  .bold-name-company {
    text-transform: uppercase;
  }
}
