.section-clients {
  .clients-swiper {
    position: relative;
    overflow: hidden;
    padding: 0 0 70px 0;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .swiper-pagination {
    left: 0;
    bottom: 5px;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--sky-blue);
  }

  .swiper-pagination-bullet {
    background-color: var(--pagination-cl);
  }
}

@include for-size(tablet) {
  .section-clients {
    .clients-swiper {
      padding: 0 30px 70px 30px;
    }
  }
}

@include for-size(desktop) {
  .section-clients {
    .clients-swiper {
      padding: 0 50px 80px 50px;

      .swiper-pagination {
        bottom: -10px;
      }

      .swiper-button-next,
      .swiper-button-prev {
        display: block;
        width: 40px;
        height: 40px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none;
        border-radius: 50%;
        box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2);

        .icon {
          width: 40px;
          height: 40px;
          fill: var(--logo-cl);
        }

        &::after {
          content: "";
        }
      }
    }
  }
}
