.footer {
  padding: 25px 0;
  background-color: #caf7ff;
}
.copy {
  margin: 0;
  font-weight: 600;
  color: var(--text-font-second);
}
.policy {
  font-size: 14px;
  color: var(--text-font);
}
