:root {
  --sky-blue: #55dae9;
  --logo-cl: #2f90dd;
  --title-cl: #2075bb;
  --white: #fff;
  --white-bg: #f1f5f8;
  --sky-blue-bg: #effcfe;
  --text-font: #1c1e20;
  --text-font-second: #0f1c49;
  --color-input: #afb1b8;
  --icon-cl: #0e95a5;
  --pagination-cl: #aff4ff;
}

html {
  scroll-behavior: smooth;
}

body {
  letter-spacing: .02em;
  color: var(--text-font);
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.86;
}

@media screen and (min-width: 1200px) {
  body {
    font-size: 18px;
  }
}

.body-hidden-scroll {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a, button, input {
  -webkit-tap-highlight-color: #0000;
}

.container {
  width: 100%;
  padding: 0 15px;
}

@media screen and (min-width: 480px) {
  .container {
    width: 480px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.link {
  text-decoration: none;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-center-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.section {
  padding: 60px 0;
}

@media screen and (min-width: 768px) {
  .section {
    padding: 80px 0;
  }
}

@media screen and (min-width: 1200px) {
  .section {
    padding: 100px 0;
  }
}

.section-title {
  letter-spacing: .05em;
  text-transform: capitalize;
  color: var(--title-cl);
  align-items: baseline;
  margin: 0 0 40px;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.18;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .section-title {
    margin: 0 0 50px;
    font-size: 42px;
    line-height: 1.16;
  }
}

.is-hidden {
  display: none;
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  transition: all .25s;
  position: absolute;
  overflow: hidden;
}

.sales {
  z-index: 222;
  background-color: #f49a9a;
  background-image: linear-gradient(0deg, #e77170 0%, #d66261 40%, #d32b2a 100%);
  background-repeat: no-repeat;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.sales__text {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.slaes__snow1, .slaes__snow2, .slaes__snow3, .slaes__snow4 {
  display: none;
}

@media screen and (min-width: 420px) {
  .sales__text {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .sales__text {
    font-size: 18px;
  }

  .slaes__snow {
    background-image: url("snow.77e162ae.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    display: block;
    position: absolute;
    top: 0;
  }

  .slaes__snow1 {
    width: 32px;
    height: 32px;
    animation-name: snow1;
    animation-duration: 4s;
    left: 10%;
  }

  .slaes__snow2 {
    width: 28px;
    height: 28px;
    animation-name: snow2;
    animation-duration: 3s;
    left: 25%;
  }

  .slaes__snow3 {
    width: 32px;
    height: 32px;
    animation-name: snow3;
    animation-duration: 3.2s;
    left: auto;
    right: 10%;
  }

  .slaes__snow4 {
    width: 30px;
    height: 30px;
    animation-name: snow4;
    animation-duration: 4.2s;
    left: auto;
    right: 25%;
  }

  @keyframes snow1 {
    0% {
      transform: translateY(-200%);
    }

    100% {
      transform: translateY(300%);
    }
  }

  @keyframes snow2 {
    0% {
      transform: translateY(-200%);
    }

    100% {
      transform: translateY(300%);
    }
  }

  @keyframes snow3 {
    0% {
      transform: translateY(-200%);
    }

    100% {
      transform: translateY(300%);
    }
  }

  @keyframes snow4 {
    0% {
      transform: translateY(-200%);
    }

    100% {
      transform: translateY(300%);
    }
  }
}

@media screen and (min-width: 1200px) {
  .sales {
    padding: 10px 30px;
  }

  .sales__text {
    font-size: 24px;
  }
}

.header {
  z-index: 22;
  padding: 15px 5px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .header {
    padding: 15px 10px;
  }
}

@media screen and (min-width: 1200px) {
  .header {
    border-bottom: 1px solid #0000001a;
    padding: 25px 20px;
  }
}

.header__nav {
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
}

.logo {
  text-transform: capitalize;
  color: var(--logo-cl);
  z-index: 4;
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  position: relative;
}

@media screen and (min-width: 370px) {
  .logo {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  .logo {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .logo {
    font-size: 26px;
  }
}

@media screen and (max-width: 1199px) {
  .list-nav {
    text-align: center;
    background-color: var(--white);
    z-index: 3;
    width: 100%;
    height: 370px;
    padding: 100px 25px 20px;
    transition: transform .25s linear, box-shadow .35s linear;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }
}

@media screen and (min-width: 768px) {
  .list-nav {
    padding: 130px 25px 20px;
  }
}

@media screen and (min-width: 1200px) {
  .list-nav {
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    display: flex;
  }
}

@media screen and (max-width: 1199px) {
  .list-nav--is-show {
    transform: translateY(0);
    box-shadow: 5px 2px 8px #223c5033;
  }
}

@media screen and (max-width: 479px) {
  .list-nav__item:last-child {
    margin-top: 5%;
  }
}

@media screen and (min-width: 480px) {
  .list-nav__item:not(:last-child) {
    margin: 0 0 10px;
  }

  .list-nav__item:last-child {
    margin-top: 10%;
  }
}

@media screen and (min-width: 707px) {
  .list-nav__item:not(:last-child) {
    margin: 0 0 5px;
  }

  .list-nav__item:last-child {
    margin-top: 10%;
  }
}

@media screen and (min-width: 768px) {
  .list-nav__item:not(:last-child) {
    margin: 0 0 20px;
  }

  .list-nav__item:last-child {
    margin-top: 10%;
  }
}

@media screen and (min-width: 1070px) {
  .list-nav__item:not(:last-child) {
    margin: 0 0 25px;
  }

  .list-nav__item:last-child {
    margin-top: 10%;
  }
}

@media screen and (max-width: 1199px) {
  .list-nav__item {
    opacity: 0;
    transition-duration: 0s;
  }
}

@media screen and (min-width: 1200px) {
  .list-nav__item:not(:last-child) {
    margin: 0 20px 0 0;
  }
}

.list-nav__item--is-show {
  opacity: 1;
  transition: all .25s linear .25s;
}

.list-nav__link {
  letter-spacing: .02em;
  color: var(--sky-blue);
  padding: 5px;
  font-size: 18px;
  font-weight: 700;
  transition: all .25s linear;
}

.list-nav__link:hover, .list-nav__link:focus {
  color: #223c50b3;
}

.container-btn {
  z-index: 5;
  align-items: center;
  margin-top: 5px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .container-btn {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .container-btn {
    margin: 0;
  }
}

.btn-header {
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 2px;
  display: inline-block;
}

.btn-header:first-child {
  border-right: 1px solid var(--text-font);
  margin-right: 5px;
  padding: 2px 10px 5px 2px;
}

@media screen and (min-width: 1200px) {
  .btn-header {
    display: none;
  }
}

.link-tel {
  letter-spacing: .02em;
  color: var(--logo-cl);
  font-size: 24px;
  font-weight: 700;
  transition: all .25s linear;
}

.link-tel:hover, .link-tel:focus {
  color: #223c50b3;
}

@media screen and (min-width: 1200px) {
  .link-tel {
    display: inline-block;
  }
}

@media screen and (max-width: 1199px) {
  .link-tel__number {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .link-tel__number {
    white-space: nowrap;
  }
}

.icon-phone {
  margin-top: 7px;
}

@media screen and (min-width: 1200px) {
  .icon-phone {
    display: none;
  }
}

.icon-menu, .icon-cross, .icon-phone {
  fill: var(--sky-blue);
  width: 28px;
  height: 28px;
}

@media screen and (min-width: 768px) {
  .icon-menu, .icon-cross, .icon-phone {
    width: 30px;
    height: 30px;
  }
}

.icon-cross, .is-hidden {
  display: none;
}

.is-show {
  display: block;
}

.hero-swiper {
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .hero-swiper {
    display: none;
  }
}

.swiper-slide__box {
  background-color: green;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0 30px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .swiper-slide__box {
    min-height: 640px;
  }
}

.swiper-slide__box--img-girl {
  background-image: linear-gradient(#02011199 0% 50%, #02011199 100%), url("bg-img-1.8690e821.jpg");
}

@media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  .swiper-slide__box--img-girl {
    background-image: linear-gradient(#02011199 0% 50%, #02011199 100%), url("bg-img-1@2x.19c6cce5.jpg");
  }
}

.swiper-slide__box--img-child {
  background-image: linear-gradient(#02011199 0% 50%, #02011199 100%), url("bg-img-2.733944cf.jpg");
  background-position: center;
}

@media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  .swiper-slide__box--img-child {
    background-image: linear-gradient(#02011199 0% 50%, #02011199 100%), url("bg-img-2@2x.555ec675.jpg");
  }
}

.swiper-slide__box--img-green {
  background-image: linear-gradient(#02011199 0% 50%, #02011199 100%), url("bg-img-3.c2a0d1ab.jpg");
}

@media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  .swiper-slide__box--img-green {
    background-image: linear-gradient(#02011199 0% 50%, #02011199 100%), url("bg-img-3@2x.88af25db.jpg");
  }
}

.swiper-slide__box--hands {
  background-image: linear-gradient(#02011199 0% 50%, #02011199 100%), url("bg-img-4.0078bcc6.jpg");
}

@media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  .swiper-slide__box--hands {
    background-image: linear-gradient(#02011199 0% 50%, #02011199 100%), url("bg-img-4@2x.15f0cf11.jpg");
  }
}

.swiper-slide__questions {
  color: var(--white);
  margin: 0 0 30px;
  font-size: 28px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .swiper-slide__questions {
    margin: 0 0 50px;
    font-size: 36px;
  }
}

.swiper-pagination-bullet {
  background-color: var(--sky-blue);
  opacity: 1;
  width: 10px;
  height: 10px;
}

.swiper-pagination-bullet-active {
  background-color: var(--white);
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 50px;
  left: -80px;
}

@media screen and (min-width: 380px) {
  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    left: -110px;
  }
}

@media screen and (min-width: 440px) {
  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    left: -140px;
  }
}

@media screen and (min-width: 480px) {
  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 20px;
    left: 0;
  }
}

.btn-order-analysis {
  letter-spacing: .09em;
  text-transform: uppercase;
  text-align: center;
  color: var(--white);
  background-color: var(--sky-blue);
  cursor: pointer;
  border: none;
  border-radius: 2px;
  align-self: flex-start;
  margin: 0;
  padding: 10px 20px;
  font-weight: 600;
  line-height: 1.33;
  transition: all .25s linear;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .btn-order-analysis {
    padding: 15px 25px;
  }
}

.hero-cards-desktop {
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

figure.card {
  background-image: linear-gradient(#02011166 0% 50%, #02011166 100%), url("bg-img-1.8690e821.jpg");
  background-position: 50%;
  background-size: 125%;
  background-clip: content-box;
  width: 370px;
  height: 270px;
  transition: all .4s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 6.7px 5.3px #00000008, 0 22.3px 17.9px #0000000d, 0 100px 80px #00000012;
}

@media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  figure.card {
    background-image: linear-gradient(#02011166 0% 50%, #02011166 100%), url("bg-img-1@2x.19c6cce5.jpg");
  }
}

figure.card:nth-child(2) {
  background-image: linear-gradient(#02011166 0% 50%, #02011166 100%), url("bg-img-2.733944cf.jpg");
}

@media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  figure.card:nth-child(2) {
    background-image: linear-gradient(#02011166 0% 50%, #02011166 100%), url("bg-img-2@2x.555ec675.jpg");
  }
}

figure.card:nth-child(3) {
  background-image: linear-gradient(#02011180 0% 50%, #02011180 100%), url("bg-img-3.c2a0d1ab.jpg");
}

@media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  figure.card:nth-child(3) {
    background-image: linear-gradient(#02011180 0% 50%, #02011180 100%), url("bg-img-3@2x.88af25db.jpg");
  }
}

figure.card:nth-child(4) {
  background-image: linear-gradient(#02011166 0% 50%, #02011166 100%), url("bg-img-4.0078bcc6.jpg");
}

@media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  figure.card:nth-child(4) {
    background-image: linear-gradient(#02011199 0% 50%, #02011199 100%), url("bg-img-4@2x.15f0cf11.jpg");
  }
}

figure.card:hover figcaption {
  transform: translateY(0);
}

figure.card--1:hover {
  background-position: 50% 100%;
}

figure.card--1 figcaption {
  bottom: 0;
  transform: translateY(80px);
}

figure.card--2 {
  background-size: 110%;
}

figure.card--2:hover {
  background-position: 50% -100%;
}

figure.card--2 figcaption {
  top: 0;
  transform: translateY(-80px);
}

figure.card--3 {
  background-size: 115%;
}

figure.card--3:hover {
  background-position: -100%;
}

figure.card--3 figcaption {
  left: 0;
  transform: translateX(-180px);
}

figure.card--4:hover {
  background-position: 100%;
}

figure.card--4 figcaption {
  right: 0;
  transform: translateX(180px);
}

figure.card--1 figcaption, figure.card--2 figcaption {
  width: 100%;
  height: 80px;
  padding: 15px 20px;
}

figure.card--3 figcaption, figure.card--4 figcaption {
  flex-direction: column;
  width: 180px;
  height: 100%;
  padding: 20px 15px;
}

figure.card .hero-card-questions {
  color: var(--white);
  width: 345px;
  font-size: 21px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

figure.card figcaption {
  background: var(--title-cl);
  justify-content: space-between;
  align-items: center;
  transition: transform .4s;
  display: flex;
  position: absolute;
}

figure.card figcaption .info {
  color: #e2e2e2;
  font-family: Montserrat;
}

figure.card figcaption .info .text {
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  display: inline-block;
}

figure.card figcaption .links {
  justify-content: end;
  align-items: center;
  display: flex;
}

figure.card figcaption .links a {
  color: #fff;
  opacity: .65;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin-left: 10px;
  font-size: 1.2rem;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

figure.card figcaption .links a:hover {
  opacity: 1;
}

figure.card figcaption .links a:focus {
  outline: none;
}

figure.card figcaption .links a:focus:after {
  opacity: 1;
  transform: scale(1);
}

figure.card figcaption .links a:after {
  content: "";
  opacity: 0;
  z-index: -1;
  background: #ffffff0d;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all .15s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.5);
}

@media screen and (min-width: 1200px) {
  figure.card {
    margin: 10px;
  }
}

@media screen and (min-width: 1440px) {
  figure.card {
    width: 410px;
    height: 270px;
    margin: 18px 40px;
  }
}

.up-btn {
  cursor: pointer;
  z-index: 22;
  background-image: linear-gradient(300deg, #55dae9 0%, #2f90dd 50%, #2075bb 100%);
  border: none;
  border-radius: 50%;
  margin: 0;
  padding: 10px;
  transition: transform .25s;
  display: inline-block;
  position: fixed;
  bottom: 10%;
  right: 5%;
  transform: translateY(400%);
  box-shadow: 0 0 10px 1px #223c5033;
}

.up-btn__icon {
  fill: var(--white);
  width: 25px;
  height: 25px;
}

.btn-anim {
  transform: translateY(20%);
}

table {
  color: var(--text-font-second);
  background-color: var(--white);
  border-collapse: collapse;
  border: 1px solid var(--text-font-second);
  width: 100%;
  font-family: Montserrat, sans-serif;
  box-shadow: 5px 20px 50px #1070b133;
}

tr, th, td {
  border: 1px solid var(--text-font-second);
}

td:first-child {
  font-size: 16px;
  font-weight: 500;
}

@media screen and (min-width: 1200px) {
  td:first-child {
    font-size: 18px;
  }
}

td:nth-child(2) {
  text-align: center;
}

th {
  padding: 10px;
  font-size: 18px;
  line-height: 1.67;
}

td {
  padding: 5px 30px;
}

.table__title {
  color: var(--title-cl);
  margin: 0 0 10px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.67;
}

@media screen and (min-width: 768px) {
  .table__title {
    font-size: 24px;
  }
}

.montage-swiper {
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
}

.montage-swiper .swiper-button-next, .montage-swiper .swiper-button-prev {
  display: none;
}

.montage-swiper .swiper-pagination {
  bottom: 5px;
  left: 0;
}

.montage-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--sky-blue);
}

.montage-swiper .swiper-pagination-bullet {
  background-color: var(--pagination-cl);
}

@media screen and (min-width: 768px) {
  .montage-swiper {
    padding: 0 50px 40px;
  }

  .montage-swiper .swiper-button-next, .montage-swiper .swiper-button-prev {
    -webkit-tap-highlight-color: #0000;
    border-radius: 50%;
    outline: none;
    width: 30px;
    height: 30px;
    display: block;
  }

  .montage-swiper .swiper-button-next .icon, .montage-swiper .swiper-button-prev .icon {
    fill: var(--logo-cl);
    width: 30px;
    height: 30px;
  }

  .montage-swiper .swiper-button-next:after, .montage-swiper .swiper-button-prev:after {
    content: "";
  }
}

@media screen and (min-width: 1200px) {
  .montage-swiper .swiper-button-next, .montage-swiper .swiper-button-prev {
    width: 40px;
    height: 40px;
    box-shadow: 0 0 8px 1px #223c5033;
  }

  .montage-swiper .swiper-button-next .icon, .montage-swiper .swiper-button-prev .icon {
    width: 40px;
    height: 40px;
  }

  .montage-swiper .swiper-button-next {
    right: 0;
  }

  .montage-swiper .swiper-button-prev {
    left: 0;
  }
}

.section-work .swiper__slider-work {
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .section-work .swiper__slider-work {
    width: 50%;
  }
}

.section-work .swiper-button-next, .section-work .swiper-button-prev {
  -webkit-tap-highlight-color: #0000;
  outline: none;
  width: 50px;
  height: 50px;
}

.section-work .swiper-button-next .icon, .section-work .swiper-button-prev .icon {
  fill: var(--white);
  width: 40px;
  height: 40px;
}

.section-work .swiper-button-next:after, .section-work .swiper-button-prev:after {
  content: "";
}

.section-clients .clients-swiper {
  padding: 0 0 70px;
  position: relative;
  overflow: hidden;
}

.section-clients .swiper-slide {
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-clients .swiper-button-next, .section-clients .swiper-button-prev {
  display: none;
}

.section-clients .swiper-pagination {
  bottom: 5px;
  left: 0;
}

.section-clients .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--sky-blue);
}

.section-clients .swiper-pagination-bullet {
  background-color: var(--pagination-cl);
}

@media screen and (min-width: 768px) {
  .section-clients .clients-swiper {
    padding: 0 30px 70px;
  }
}

@media screen and (min-width: 1200px) {
  .section-clients .clients-swiper {
    padding: 0 50px 80px;
  }

  .section-clients .clients-swiper .swiper-pagination {
    bottom: -10px;
  }

  .section-clients .clients-swiper .swiper-button-next, .section-clients .clients-swiper .swiper-button-prev {
    -webkit-tap-highlight-color: #0000;
    border-radius: 50%;
    outline: none;
    width: 40px;
    height: 40px;
    display: block;
    box-shadow: 0 0 8px 1px #223c5033;
  }

  .section-clients .clients-swiper .swiper-button-next .icon, .section-clients .clients-swiper .swiper-button-prev .icon {
    fill: var(--logo-cl);
    width: 40px;
    height: 40px;
  }

  .section-clients .clients-swiper .swiper-button-next:after, .section-clients .clients-swiper .swiper-button-prev:after {
    content: "";
  }
}

.list-social-menu {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .list-social-menu {
    display: none;
  }
}

.list-social-menu__item:not(:last-child) {
  margin-right: 10px;
}

.list-social-menu__link {
  padding: 5px;
  display: block;
}

.list-social-menu__icon {
  fill: var(--sky-blue);
  width: 25px;
  height: 25px;
}

.list-social-footer {
  display: flex;
}

.list-social-footer__item {
  cursor: pointer;
  padding: 5px;
}

.list-social-footer__item:not(:last-child) {
  margin-right: 10px;
}

.list-social-footer__icon {
  fill: var(--text-font-second);
  width: 35px;
  height: 35px;
  transition: fill .25s linear;
}

.list-social-footer__icon--instagram:hover, .list-social-footer__icon--instagram:focus {
  fill: #cf5063;
}

.list-social-footer__icon--facebook:hover, .list-social-footer__icon--facebook:focus {
  fill: #4867aa;
}

.form {
  flex-direction: column;
  width: 100%;
  padding: 30px;
  display: flex;
}

.form__field {
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
}

.form__input {
  letter-spacing: 1px;
  color: var(--color-input);
  -webkit-outline: none;
  -webkit-border: none;
  -webkit-border-bottom: 2px solid var(--color-input);
  border: none;
  border-bottom: 2px solid var(--color-input);
  background-color: #0000;
  border-radius: 0;
  outline: none;
  width: 100%;
  padding: 10px 10px 10px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  display: block;
}

.input-promocode::placeholder {
  letter-spacing: 1px;
  color: var(--color-input);
  font-size: 18px;
  font-weight: 500;
}

.form__label {
  letter-spacing: 1px;
  color: var(--color-input);
  font-size: 18px;
  font-weight: 500;
  transition: all .15s linear;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.form__label .requared {
  color: red;
}

.form__label-is-focus {
  color: var(--text-font-second);
  top: -10px;
  left: 0;
}

.form__message {
  color: var(--color-input);
  -webkit-outline: none;
  -webkit-border: none;
  -webkit-border-bottom: 2px solid var(--color-input);
  border: none;
  border-bottom: 2px solid var(--color-input);
  resize: none;
  background-color: #0000;
  border-radius: 0;
  outline: none;
  margin-bottom: 20px;
  padding: 10px 10px 10px 0;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
}

.form__message::placeholder {
  letter-spacing: 1px;
  color: var(--color-input);
  font-size: 18px;
  font-weight: 500;
}

.form__container-btn {
  text-align: center;
}

.form__btn {
  border: 1px solid var(--sky-blue);
  box-shadow: 0 1px 8px #223c5033;
}

.form__btn:hover, .form__btn:focus {
  color: var(--sky-blue);
  background-color: var(--white);
}

.form__input--color-is-focus {
  -webkit-border-bottom: 2px solid var(--text-font-second);
  border-bottom: 2px solid var(--text-font-second);
}

.footer {
  background-color: #caf7ff;
  padding: 25px 0;
}

.copy {
  color: var(--text-font-second);
  margin: 0;
  font-weight: 600;
}

.policy {
  color: var(--text-font);
  font-size: 14px;
}

.brighttheme-container {
  border: none !important;
}

.pnotify-title .pnotify-pre-line, .brighttheme-title .pnotify-pre-line {
  font-size: 24px;
  font-weight: 700;
}

.pnotify-text .pnotify-pre-line, .brighttheme-text .pnotify-pre-line {
  font-size: 18px;
  font-weight: 500;
}

.pnotify-pre-line {
  color: #fff;
}

.container-for-btn {
  justify-content: center;
  display: flex;
}

.hero {
  background-color: #223c5099;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .hero {
    background-color: inherit;
    background-image: url("drop-large.dd73afc3.png");
    background-position: 0 100%, right 90%;
    background-repeat: no-repeat;
    background-size: 30%, 25%;
    padding: 50px 0;
  }
}

@media screen and (min-width: 1200px) and (min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  .hero {
    background-image: url("drop-large@2x.e6cede85.png");
  }
}

.hero--shadow-bottom {
  box-shadow: 0 0 10px -2px #223c504d;
}

@media screen and (min-width: 1200px) {
  .hero--shadow-bottom {
    box-shadow: none;
  }
}

.container-hero {
  display: none;
}

@media screen and (min-width: 1200px) {
  .container-hero {
    justify-content: space-between;
    width: 1170px;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .container-hero {
    width: 1440px;
  }
}

.container-cards {
  width: 70%;
}

.list-cards {
  flex-wrap: wrap;
  margin: -15px;
  display: flex;
}

.list-cards__questions--card {
  color: var(--white);
  margin: 0;
  font-size: 23px;
  font-weight: 700;
}

.container-order-hero {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  display: flex;
}

.btn-order-analysis__desktop {
  border: 1px solid var(--sky-blue);
  align-self: center;
  max-width: 350px;
  padding: 20px 40px;
  transition: all .25s linear;
  box-shadow: 0 1px 8px #223c5033;
}

.btn-order-analysis__desktop:not(:last-child) {
  margin-bottom: 35px;
}

.btn-order-analysis__desktop:hover, .btn-order-analysis__desktop:focus {
  color: var(--sky-blue);
  background-color: var(--white);
}

.section-about {
  background-image: linear-gradient(#55dae900 0%, #55dae90e 50%, #55dae91a 100%);
}

@media screen and (min-width: 1200px) {
  .section-about {
    background-image: url("drop-mini-small.985ed61c.png"), url("drop-mini-mid.d26f4fc3.png"), url("drop-mini-large.11377655.png"), url("pattern.8648614f.png"), url("pattern.8648614f.png"), linear-gradient(#55dae900 0%, #55dae90e 50%, #55dae91a 100%);
    background-position: 95% 95%, 5% 70%, 35% 35%, 55% 100%, 85% 5%, 0 0;
    background-repeat: no-repeat;
    background-size: 130px, 100px, 200px, auto, auto, auto;
  }
}

@media screen and (min-width: 1200px) and (min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  .section-about {
    background-image: url("drop-mini-small@2x.ef66e956.png"), url("drop-mini-mid@2x.b1c9efe1.png"), url("drop-mini-large@2x.546d30dd.png"), url("pattern@2x.25400e54.png"), url("pattern@2x.25400e54.png");
  }
}

@media screen and (min-width: 768px) {
  .about-container-content {
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .about-container-content {
    align-items: flex-start;
  }
}

@media screen and (min-width: 768px) {
  .about-container-content__text {
    width: 50%;
    margin-right: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .about-container-content__text {
    width: 50%;
    margin-right: 60px;
  }
}

.about-container-content__overhead {
  color: var(--text-font-second);
  margin: 0 0 20px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.62;
}

@media screen and (min-width: 768px) {
  .about-container-content__overhead {
    margin: 0 0 40px;
  }
}

@media screen and (min-width: 1200px) {
  .about-container-content__overhead {
    font-size: 21px;
    line-height: 1.67;
  }
}

.about-container-content__desc {
  text-align: justify;
  margin: 0;
}

.about-container-content__desc .bold-name-company {
  text-transform: uppercase;
  font-weight: 700;
}

.about-container-content__desc .analysis {
  color: var(--text-font-second);
  font-style: normal;
}

.about-container-content__img {
  display: none;
}

@media screen and (min-width: 768px) {
  .about-container-content__img {
    width: auto;
    display: inline-block;
  }
}

.img-box {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 5px 20px 50px #1070b133;
}

.section-result {
  background-color: #effcfd;
}

.section-result .title-result {
  justify-content: center;
  margin-bottom: 40px;
}

.section-result .text {
  margin: 0 0 30px;
}

@media screen and (min-width: 1200px) {
  .section-result .text {
    width: 79%;
  }

  .section-result {
    background-image: url("circle-blue.1e84ba56.png"), url("circle-white.87c96d5d.png"), url("circle-blue.1e84ba56.png"), url("circle-white.87c96d5d.png"), url("circle-blue.1e84ba56.png"), url("circle-white.87c96d5d.png"), url("circle-blue.1e84ba56.png"), url("circle-white.87c96d5d.png"), url("circle-blue.1e84ba56.png"), url("circle-white.87c96d5d.png"), url("circle-blue.1e84ba56.png"), url("circle-white.87c96d5d.png"), url("circle-blue.1e84ba56.png"), url("circle-white.87c96d5d.png"), url("circle-blue.1e84ba56.png"), url("circle-white.87c96d5d.png");
    background-position: 5% 95%, 35% 29%, 18% 75%, 10% 65%, 15%, 18% 10%, -1% 8%, 5% 40%, 85% 20%, 95% 10%, 75% 35%, 90%, 95% 38%, 101% 70%, 90% 85%, 10% 25%;
    background-repeat: no-repeat;
    background-size: 110px, 90px, 85px, 80px, 60px, auto, 40px, 70px, 40px, 50px, 55px, 80px, 60px, 100px, 110px, auto;
  }
}

@media screen and (min-width: 1200px) and (min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  .section-result {
    background-image: url("circle-blue@2x.5351aa4a.png"), url("circle-white@2x.e06ad83e.png"), url("circle-blue@2x.5351aa4a.png"), url("circle-white@2x.e06ad83e.png"), url("circle-blue@2x.5351aa4a.png"), url("circle-white@2x.e06ad83e.png"), url("circle-blue@2x.5351aa4a.png"), url("circle-white@2x.e06ad83e.png"), url("circle-blue@2x.5351aa4a.png"), url("circle-white@2x.e06ad83e.png"), url("circle-blue@2x.5351aa4a.png"), url("circle-white@2x.e06ad83e.png"), url("circle-blue@2x.5351aa4a.png"), url("circle-white@2x.e06ad83e.png"), url("circle-blue@2x.5351aa4a.png"), url("circle-white@2x.e06ad83e.png");
  }
}

@media screen and (min-width: 768px) {
  .list-sale {
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .list-sale {
    gap: 10px;
  }
}

.list-sale__item {
  text-align: center;
  border: 2px solid var(--sky-blue);
  background-color: var(--white);
  cursor: pointer;
  border-radius: 14px;
  padding: 20px;
  box-shadow: 1px 2px 8px -3px #223c5033;
}

@media screen and (max-width: 767px) {
  .list-sale__item:not(:last-child) {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .list-sale__item {
    flex-basis: calc(50% - 10px);
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {
  .list-sale__item {
    flex-basis: calc(25% - 10px);
    transition: border-color .25s linear;
  }

  .list-sale__item:hover, .list-sale__item:focus {
    border-color: var(--icon-cl);
  }

  .list-sale__item:hover .list-sale__icon, .list-sale__item:focus .list-sale__icon {
    fill: var(--icon-cl);
  }
}

.list-sale__icon {
  fill: var(--sky-blue);
  width: 70px;
  height: 70px;
  transition: fill .25s linear;
}

.list-sale__title {
  color: var(--text-font-second);
  margin: 20px 0 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.62;
}

@media screen and (min-width: 1200px) {
  .list-sale__title {
    font-size: 18px;
    line-height: 1.67;
  }
}

.result-container-table {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (min-width: 1200px) {
  .result-container-table {
    margin-bottom: 70px;
  }
}

.questions-title {
  letter-spacing: .05em;
  text-align: center;
  text-transform: capitalize;
  color: var(--title-cl);
  margin: 0 0 40px;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.18;
}

@media screen and (min-width: 1200px) {
  .questions-title {
    margin: 0 0 50px;
    font-size: 32px;
    line-height: 1.16;
  }
}

.list-questions {
  margin-bottom: 60px;
}

.list-questions .item {
  background-color: #b1f7ff;
  border: none;
  border-radius: 18px;
  padding: 20px;
  transition: all .25s;
  position: relative;
}

.list-questions .item:not(:last-child) {
  margin-bottom: 20px;
}

.list-questions .item.active {
  background-color: var(--white);
}

.list-questions .item .box {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.list-questions .question {
  margin: 0;
  font-weight: 700;
}

.list-questions .open-button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 5px;
  display: flex;
}

.list-questions .open-button .icon {
  fill: var(--logo-cl);
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 768px) {
  .list-questions {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .list-questions .item {
    width: 650px;
  }
}

@media screen and (min-width: 1200px) {
  .questions-title {
    margin: 0 0 75px;
  }

  .desktop-box {
    height: 405px;
    position: relative;
  }

  .list-questions {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
  }

  .list-questions .item {
    width: 750px;
    padding: 30px;
  }

  .list-questions .item:not(:last-child) {
    margin-bottom: 35px;
  }
}

.section-maintenance {
  background-image: linear-gradient(#effcfd 0%, #fff6 30% 100%);
  background-position: center, right 500px;
  background-repeat: no-repeat;
  background-size: cover, 440px;
}

.section-maintenance .text {
  text-align: justify;
}

.section-maintenance .text.overhead {
  font-weight: 700;
}

.box-text {
  margin-bottom: 50px;
}

.box-text .text-list {
  margin-bottom: 30px;
}

.box-text .text-list .item:not(:last-child) {
  margin-bottom: 20px;
}

.box-text .text-list .item .text {
  margin: 0;
}

.box-text .text-list .item .text .bold {
  font-weight: 700;
}

.maintenance-img-list {
  margin-bottom: 50px;
}

.maintenance-img-list .item {
  position: relative;
  overflow: hidden;
}

.maintenance-img-list .item:not(:last-child) {
  margin-bottom: 30px;
}

.maintenance-img-list .maintenance-card {
  transition: all .4s;
}

.maintenance-img-list .maintenance-card .card-img {
  transition: all .4s;
  transform: translateX(0);
}

.maintenance-img-list .maintenance-card:hover .container-info, .maintenance-img-list .maintenance-card:focus .container-info {
  transform: translateX(0);
}

.maintenance-img-list .maintenance-card:hover img, .maintenance-img-list .maintenance-card:focus img {
  transform: translateX(10%);
}

.maintenance-img-list .container-info {
  background-color: var(--title-cl);
  z-index: 2;
  width: 40%;
  height: 100%;
  padding: 20px 10px;
  transition: all .4s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-105%);
}

.maintenance-img-list .container-info .info {
  color: var(--white);
  font-size: 13px;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (min-width: 768px) {
  .section-maintenance .box-text {
    width: 75%;
  }

  .section-maintenance .title-maintenance {
    width: 70%;
  }

  .maintenance-img-list {
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
  }

  .maintenance-img-list .item {
    flex-basis: calc(50% - 10px);
  }

  .maintenance-img-list .item:not(:last-child) {
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {
  .section-maintenance {
    background-image: linear-gradient(#effcfd 0%, #fff6 30% 100%), url("bg-img.baab30b2.jpg");
  }
}

@media screen and (min-width: 1200px) and (min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  .section-maintenance {
    background-image: linear-gradient(#effcfd 0%, #fff6 30% 100%), url("bg-img@2x.0cf2f0cc.jpg");
  }
}

@media screen and (min-width: 1200px) {
  .maintenance-img-list {
    gap: 40px;
    margin-bottom: 70px;
  }

  .maintenance-img-list .item {
    flex-basis: calc(50% - 20px);
  }

  .maintenance-img-list .container-info .info {
    font-size: 14px;
  }
}

.section-montage {
  background-image: linear-gradient(0deg, #effcfe 0%, #fffc 5% 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.section-montage .subhead {
  color: var(--text-font);
  margin-bottom: 30px;
  font-size: 21px;
  font-weight: 700;
}

.box-content-business .text, .box-content-home .text {
  text-align: justify;
}

.box-content-business .text.overhead, .box-content-home .text.overhead {
  font-weight: 700;
}

.list-process-business .item:not(:last-child) {
  margin-bottom: 15px;
}

.list-process-business .bold {
  font-weight: 700;
}

.list-process-home .item:not(:last-child) {
  margin-bottom: 15px;
}

.list-process-home .bold {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .section-montage .title-montage {
    width: 70%;
  }

  .section-montage .subhead {
    font-size: 24px;
  }

  .box-content-business .box-text, .box-content-home .box-text {
    width: 70%;
  }
}

.section-favourite {
  text-align: center;
  background-color: #effcfe;
}

.section-favourite .section-title {
  justify-content: center;
}

.section-favourite .container-for-btn {
  margin-top: 70px;
}

@media screen and (min-width: 1200px) {
  .section-favourite .container-for-btn {
    margin-top: 100px;
  }

  .section-favourite {
    text-align: left;
  }

  .section-favourite .section-title {
    justify-content: flex-start;
  }
}

.section-favourite__subhead {
  color: var(--text-font-second);
  margin: 0 0 20px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.67;
}

@media screen and (min-width: 1200px) {
  .section-favourite__subhead {
    margin: 0 0 30px;
    font-size: 28px;
    line-height: 1.18;
  }
}

@media screen and (min-width: 768px) {
  .favourite-list {
    flex-wrap: wrap;
    justify-content: center;
    margin: -5px;
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .favourite-list {
    margin: -10px;
  }
}

@media screen and (max-width: 767px) {
  .favourite-list__item:not(:last-child) {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .favourite-list__item {
    flex-basis: calc(50% - 20px);
    margin: 5px;
  }
}

@media screen and (min-width: 1200px) {
  .favourite-list__item {
    text-align: center;
    cursor: pointer;
    flex-basis: calc(25% - 20px);
    margin: 10px;
  }

  .favourite-list__item:hover .favourite-list__icon, .favourite-list__item:focus .favourite-list__icon {
    fill: var(--icon-cl);
  }
}

.favourite-list__icon {
  fill: var(--sky-blue);
  width: 100px;
  height: 100px;
  transition: fill .25s linear;
}

.favourite-list__num {
  color: var(--title-cl);
  font-size: 36px;
  font-weight: 700;
  display: inline-block;
}

@media screen and (min-width: 1200px) {
  .favourite-list__num {
    margin-bottom: 10px;
  }
}

.favourite-list__text {
  margin: 0;
}

.section-work {
  background-color: var(--sky-blue-bg);
}

@media screen and (min-width: 768px) {
  .section-work {
    text-align: start;
  }

  .work-container-content {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .work-container-content {
    flex-direction: row-reverse;
  }
}

.work-container-content__text-box {
  margin: 0 0 30px;
}

@media screen and (min-width: 768px) {
  .work-container-content__text-box {
    width: 50%;
    margin: 0 20px 0 0;
  }
}

@media screen and (min-width: 1200px) {
  .work-container-content__text-box {
    margin: 0 0 0 50px;
  }
}

.work-container-content__text {
  text-align: justify;
  margin: 0;
}

.work-container-content__text .bold-name-company {
  text-transform: uppercase;
}

.section-clients {
  background-image: linear-gradient(#effcfe 0%, #effcfe33 10%, #effcfe00 20%);
}

.section-clients .section-title {
  justify-content: center;
}

.section-clients .overhead {
  margin: 0 0 20px;
  font-weight: 700;
}

.section-clients .text {
  text-align: justify;
  margin: 0 0 40px;
}

@media screen and (min-width: 768px) {
  .section-clients .text {
    width: 70%;
  }
}

@media screen and (min-width: 1200px) {
  .section-clients {
    padding-bottom: 100px;
  }

  .section-clients .section-title {
    justify-content: flex-start;
  }

  .section-clients .text {
    margin: 0 0 70px;
  }
}

.section-order-form {
  text-align: center;
}

.section-order-form .section-title {
  justify-content: center;
  margin: 0 0 10px;
}

@media screen and (min-width: 1200px) {
  .section-order-form {
    background-image: url("drop-reverse.7d82d839.png"), url("circle.4f973692.png");
    background-position: 100% -20%, -25% 135%;
    background-repeat: no-repeat;
    background-size: 230px, auto;
    width: 50%;
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (min-width: 1200px) and (min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 1200px) and (min-resolution: 192dpi), screen and (min-width: 1200px) and (min-resolution: 2dppx) {
  .section-order-form {
    background-image: url("drop-reverse@2x.4033b74d.png"), url("circle@2x.ac24a7cc.png");
  }
}

@media screen and (min-width: 1200px) {
  .section-order-form__container {
    width: 100%;
  }
}

.section-order-form__subhead {
  margin: 0 0 20px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.62;
}

@media screen and (min-width: 768px) {
  .section-order-form__subhead {
    font-size: 18px;
    line-height: 1.67;
  }
}

@media screen and (min-width: 1200px) {
  .container-flex-section {
    display: flex;
  }
}

.section-contacts {
  background-image: linear-gradient(90deg, #ffffffd9 0% 50%, #ffffffd9 100%), url("contacts-bg.73d2610e.jpg");
  background-position: 40% 0;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
  .section-contacts {
    background-image: linear-gradient(90deg, #ffffffd9 0% 50%, #ffffffd9 100%), url("contacts-bg@2x.bbf01873.jpg");
  }
}

@media screen and (min-width: 1200px) {
  .section-contacts {
    width: 50%;
    padding-left: 100px;
    padding-right: 100px;
  }

  .section-container-contacts {
    width: 100%;
  }
}

address {
  margin-bottom: 30px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
}

.address-list__item:not(:last-child) {
  margin-bottom: 15px;
}

.address-list__link {
  color: var(--text-font-second);
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.66;
  display: flex;
}

@media screen and (min-width: 768px) {
  .address-list__link {
    font-size: 24px;
  }
}

.address-list__icon {
  fill: gray;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.section-contacts__time-work-box {
  margin-bottom: 40px;
}

.section-contacts__work-title {
  color: var(--text-font-second);
  margin: 0 0 10px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.67;
}

.section-contacts__work-title:before {
  content: "";
  background-color: currentColor;
  width: 40px;
  height: 2px;
  margin-right: 5px;
  display: inline-block;
}

@media screen and (min-width: 1200px) {
  .section-contacts__work-title {
    font-size: 28px;
    line-height: 1.18;
  }
}

.section-contacts__time-work {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.66;
}

.section-contacts__work-title--social {
  margin-bottom: 30px;
}

.section-policy {
  color: var(--accent-cl);
  background-color: var(--main-cl);
  padding: 50px 0;
}

.section-policy p {
  text-align: justify;
}

.section-policy p:not(:last-child) {
  margin-bottom: 40px;
}

.list-info {
  margin: 15px;
  padding-left: 40px;
  list-style-type: disc;
}

.list-info .item:not(:last-child) {
  margin-bottom: 15px;
}

.title {
  text-align: center;
  margin-bottom: 40px;
}

.back-link {
  text-align: center;
  text-transform: uppercase;
  color: var(--accent-cl);
  text-decoration: underline;
  display: block;
}

/*# sourceMappingURL=policy.6461fe39.css.map */
