.hero-cards-desktop {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
}

figure.card {
  position: relative;
  width: 370px;
  height: 270px;
  background-image: linear-gradient(
      180deg,
      rgba(2, 1, 17, 0.4) 0%,
      rgba(2, 1, 17, 0.4) 50%,
      rgba(2, 1, 17, 0.4) 100%
    ),
    url(../images/hero/v2/bg-img-1.jpg);
  background-position: 50% 50%;
  background-size: 125%;
  background-clip: content-box;
  transition: all 400ms ease;
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.03),
    0 22.3px 17.9px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
  overflow: hidden;

  @media screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background-image: linear-gradient(
        180deg,
        rgba(2, 1, 17, 0.4) 0%,
        rgba(2, 1, 17, 0.4) 50%,
        rgba(2, 1, 17, 0.4) 100%
      ),
      url(../images/hero/v2/bg-img-1@2x.jpg);
  }

  &:nth-child(2) {
    background-image: linear-gradient(
        180deg,
        rgba(2, 1, 17, 0.4) 0%,
        rgba(2, 1, 17, 0.4) 50%,
        rgba(2, 1, 17, 0.4) 100%
      ),
      url(../images/hero/v2/bg-img-2.jpg);

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: linear-gradient(
          180deg,
          rgba(2, 1, 17, 0.4) 0%,
          rgba(2, 1, 17, 0.4) 50%,
          rgba(2, 1, 17, 0.4) 100%
        ),
        url(../images/hero/v2/bg-img-2@2x.jpg);
    }
  }

  &:nth-child(3) {
    background-image: linear-gradient(
        180deg,
        rgba(2, 1, 17, 0.5) 0%,
        rgba(2, 1, 17, 0.5) 50%,
        rgba(2, 1, 17, 0.5) 100%
      ),
      url(../images/hero/v2/bg-img-3.jpg);

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: linear-gradient(
          180deg,
          rgba(2, 1, 17, 0.5) 0%,
          rgba(2, 1, 17, 0.5) 50%,
          rgba(2, 1, 17, 0.5) 100%
        ),
        url(../images/hero/v2/bg-img-3@2x.jpg);
    }
  }

  &:nth-child(4) {
    background-image: linear-gradient(
        180deg,
        rgba(2, 1, 17, 0.4) 0%,
        rgba(2, 1, 17, 0.4) 50%,
        rgba(2, 1, 17, 0.4) 100%
      ),
      url(../images/hero/v2/bg-img-4.jpg);

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: linear-gradient(
          180deg,
          rgba(2, 1, 17, 0.6) 0%,
          rgba(2, 1, 17, 0.6) 50%,
          rgba(2, 1, 17, 0.6) 100%
        ),
        url(../images/hero/v2/bg-img-4@2x.jpg);
    }
  }

  &:hover {
    figcaption {
      transform: translateY(0px);
    }
  }

  &--1 {
    &:hover {
      background-position: 50% 100%;
    }
    figcaption {
      bottom: 0;
      transform: translateY(80px);
    }
  }
  &--2 {
    background-size: 110%;
    &:hover {
      background-position: 50% -100%;
    }
    figcaption {
      top: 0;
      transform: translateY(-80px);
    }
  }
  &--3 {
    background-size: 115%;
    &:hover {
      background-position: -100% 50%;
    }
    figcaption {
      left: 0;
      transform: translateX(-180px);
    }
  }
  &--4 {
    &:hover {
      background-position: 100% 50%;
    }
    figcaption {
      right: 0;
      transform: translateX(180px);
    }
  }

  &--1,
  &--2 {
    figcaption {
      width: 100%;
      height: 80px;
      padding: 15px 20px;
    }
  }
  &--3,
  &--4 {
    figcaption {
      flex-direction: column;
      width: 180px;
      height: 100%;
      padding: 20px 15px;
    }
  }

  .hero-card-questions {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 345px;
    color: var(--white);
    font-size: 21px;
    font-weight: 700;
  }

  figcaption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background: var(--title-cl);
    transition: transform 400ms ease;
    .info {
      color: rgb(226, 226, 226);
      font-family: "Montserrat";
      .text {
        display: inline-block;
        color: var(--white);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.3;
      }
    }
    .links {
      display: flex;
      justify-content: end;
      align-items: center;
      a {
        text-decoration: none;
        position: relative;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-left: 10px;
        font-size: 1.2rem;
        opacity: 0.65;
        border-radius: 50%;
        overflow: hidden;
        &:hover {
          opacity: 1;
        }
        &:focus {
          outline: none;
          &::after {
            transform: scale(1);
            opacity: 1;
          }
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          opacity: 0;
          background: rgba(255, 255, 255, 0.05);
          transform: scale(0.5);
          z-index: -1;
          transition: all 150ms ease;
        }
      }
    }
  }
}

@include for-size(desktop) {
  figure.card {
    margin: 10px 10px;
  }
}

@media screen and (min-width: 1440px) {
  figure.card {
    margin: 18px 40px;
    width: 410px;
    height: 270px;
  }
}
