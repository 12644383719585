.section-montage {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  background-image: linear-gradient(
    0deg,
    rgba(239, 252, 254, 1) 0%,
    rgba(255, 255, 255, 0.8016456582633054) 5%,
    rgba(255, 255, 255, 0.8016456582633054) 100%
  );

  .subhead {
    margin-bottom: 30px;
    font-size: 21px;
    font-weight: 700;
    color: var(--text-font);
  }
}

.box-content-business,
.box-content-home {
  .text {
    text-align: justify;

    &.overhead {
      font-weight: 700;
    }
  }
}

.list-process-business {
  .item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .bold {
    font-weight: 700;
  }
}

.list-process-home {
  .item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .bold {
    font-weight: 700;
  }
}

@include for-size(tablet) {
  .section-montage {
    .title-montage {
      width: 70%;
    }

    .subhead {
      font-size: 24px;
    }
  }

  .box-content-business {
    .box-text {
      width: 70%;
    }
  }

  .box-content-home {
    .box-text {
      width: 70%;
    }
  }
}
