:root {
  --sky-blue: #55dae9;
  --logo-cl: #2f90dd;
  --title-cl: #2075bb;
  --white: #ffffff;
  --white-bg: #f1f5f8;
  --sky-blue-bg: #effcfe;
  --text-font: #1c1e20;
  --text-font-second: #0f1c49;
  --color-input: #afb1b8;
  --icon-cl: #0e95a5;
  --pagination-cl: #aff4ff;
}
