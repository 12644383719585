.header {
  position: relative;
  padding: 15px 5px;
  z-index: 22;

  @media screen and (min-width: 768px) {
    padding: 15px 10px;
  }
  @media screen and (min-width: 1200px) {
    padding: 25px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.header__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.logo {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--logo-cl);
  z-index: 4;

  @media screen and (min-width: 370px) {
    font-size: 18px;
  }
  @media screen and (min-width: 768px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 26px;
  }
}
.list-nav {
  @media screen and (max-width: 1199px) {
    position: absolute;
    top: 0;
    left: 0;
    padding: 100px 25px 20px 25px;
    width: 100%;
    height: 370px;
    text-align: center;
    background-color: var(--white);
    transform: translateY(-100%);
    transition: transform 250ms linear, box-shadow 350ms linear;
    z-index: 3;
  }

  @include for-size(tablet) {
    padding: 130px 25px 20px 25px;
  }

  @include for-size(desktop) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
  }
}
.list-nav--is-show {
  @media screen and (max-width: 1199px) {
    transform: translateY(0);
    box-shadow: 5px 2px 8px 0px rgba(34, 60, 80, 0.2);
  }
}
.list-nav__item {
  @media screen and (max-width: 479px) {
    &:last-child {
      margin-top: 5%;
    }
  }
  @include for-size(mobile) {
    &:not(:last-child) {
      margin: 0 0 10px 0;
    }
    &:last-child {
      margin-top: 10%;
    }
  }
  @media screen and (min-width: 707px) {
    &:not(:last-child) {
      margin: 0 0 5px 0;
    }
    &:last-child {
      margin-top: 10%;
    }
  }
  @media screen and (min-width: 768px) {
    &:not(:last-child) {
      margin: 0 0 20px 0;
    }
    &:last-child {
      margin-top: 10%;
    }
  }
  @media screen and (min-width: 1070px) {
    &:not(:last-child) {
      margin: 0 0 25px 0;
    }
    &:last-child {
      margin-top: 10%;
    }
  }
  @media screen and (max-width: 1199px) {
    opacity: 0;
    transition-duration: 0ms;
  }
  @media screen and (min-width: 1200px) {
    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
  }
}
.list-nav__item--is-show {
  opacity: 1;
  transition: all 250ms linear 250ms;
}
.list-nav__link {
  padding: 5px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: var(--sky-blue);
  transition: all 250ms linear;

  &:hover,
  &:focus {
    color: rgba(34, 60, 80, 0.7);
  }
}
.container-btn {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
  z-index: 5;

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    margin-top: 10px;
  }
  @media screen and (min-width: 1200px) {
    margin: 0;
  }
}
.btn-header {
  display: inline-block;
  margin: 0;
  padding: 2px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:first-child {
    margin-right: 5px;
    padding: 2px 10px 5px 2px;
    border-right: 1px solid var(--text-font);
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
}
.link-tel {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: var(--logo-cl);
  transition: all 250ms linear;

  &:hover,
  &:focus {
    color: rgba(34, 60, 80, 0.7);
  }

  @include for-size(desktop) {
    display: inline-block;
  }
}
.link-tel__number {
  @media screen and (max-width: 1199px) {
    display: none;
  }
  @include for-size(desktop) {
    white-space: nowrap;
  }
}
.icon-phone {
  margin-top: 7px;
  @include for-size(desktop) {
    display: none;
  }
}
.icon-menu,
.icon-cross,
.icon-phone {
  width: 28px;
  height: 28px;
  fill: var(--sky-blue);

  @media screen and (min-width: 768px) {
    width: 30px;
    height: 30px;
  }
}
.icon-cross {
  display: none;
}
.is-hidden {
  display: none;
}
.is-show {
  display: block;
}
